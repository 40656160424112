.spinner {
  width: 40px;
  height: 40px;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #1976d2;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.progress_wrapper {
  padding-top: 30px;
}
/* .progress_wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
} */

.h3details-header {
  background-color: #3643BA;
  color: white;
  font-size: 12px;
  button {
    color: white;
    font-size: small;
  }
  .MuiDataGrid-columnHeaderTitle {
    white-space: initial;
  }
}

.MuiDataGrid-columnHeaders {
  width: 100%;
}

.MuiDataGrid-footerContainer {
  min-height: 125px;
}
